<template>
  <div>
    <v-container>
      <v-row>
        <v-container>
          <h2>ตั้งค่าระบบ</h2>
        </v-container>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page="10"
            class="elevation-1"
            :search="searchTxt"
          >
            <template v-slot:item.Actions="{ item }">
              <v-icon class="mr-3 primary--text" @click="editLine(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.Status="{ item }">
              <v-icon class="info--text" v-if="item.Status == 1">
                mdi-check-bold
              </v-icon>
              <v-icon class="secondary--text" v-if="item.Status == 0">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <dialogEdit
      :modal="showDialogEdit"
      :item="itemDialogEdit"
      :itemIndex="itemIndexDialogEdit"
      @close="closeDialogEdit"
    ></dialogEdit>
  </div>
</template>

<script>
import dialogEdit from "./DialogSettingParameter.vue";
import { newParameter } from "@/store/modules/settings/state";
import { mapActions } from "vuex";

export default {
  components: {
    dialogEdit,
  },
  data() {
    return {
      dataTableAll: [],
      showDialogEdit: false,
      itemDialogEdit: {},
      itemIndexDialogEdit: -1,
      showTxt: "แสดงทั้งหมด",
      searchTxt: "",
      headers: [
        {
          text: "รหัส",
          value: "ParmId",
          divider: true,
          width: 130,
        },
        {
          text: "ชื่อ",
          value: "CompanyName",
          divider: true,
        },
        {
          text: "ชื่อบริษัท",
          value: "CompanyAddress",
          divider: true,
        },
        {
          text: "ที่อยู่",
          value: "CompanyTaxId",
          divider: true,
        },
        {
          text: "เบอร์โทร",
          value: "CompanyPhone",
          divider: true,
        },
        {
          text: "ราคาผู้ใหญ่",
          value: "AdultPrice",
          divider: true,
        },
        {
          text: "ราคาผู้เด็ก",
          value: "ChildPrice",
          divider: true,
        },
        {
          text: "เวลา(นาที)",
          value: "DiningTime",
          divider: true,
        },
        {
          text: "เวลาสั่งอาหารสุดท้าย",
          value: "LastOrderTimeStr",
          divider: true,
        },
        {
          text: "ที่อยู่รูปภาพ",
          value: "PathImage",
          divider: true,
        },
        {
          text: "สถานะ",
          value: "Status",
          divider: true,
          width: 130,
          align: "center",
        },
        {
          text: "",
          value: "Actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    dataTable: function () {
      if (this.showTxt == "แสดงทั้งหมด") {
        return this.dataTableAll.filter((list) => list.Status == 1);
      } else {
        return this.dataTableAll;
      }
    },
  },
  methods: {
    ...mapActions("settings", ["actParameterGets"]),
    showClick() {
      if (this.showTxt == "แสดงทั้งหมด") {
        this.showTxt = "เฉพาะใช้งาน";
      } else {
        this.showTxt = "แสดงทั้งหมด";
      }
    },
    loadData() {
      this.actParameterGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.dataTableAll = data.result;
        } else {
          this.Error(data.message);
        }
      });
    },
    addLine() {
      this.itemDialogEdit = Object.assign({}, newParameter());
      this.itemIndexDialogEdit = -1;
      this.showDialogEdit = true;
    },
    editLine(item) {
      this.itemDialogEdit = item;
      this.itemIndexDialogEdit = this.dataTable.indexOf(item);
      this.showDialogEdit = true;
    },
    closeDialogEdit(value) {
      this.showDialogEdit = value;
      this.loadData();
    },
  },
};
</script>

<style>
.container{
  max-width: 1500px;
}
</style>
